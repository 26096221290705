import React, { useState } from 'react';
import { RxCross2 } from "react-icons/rx";


const GwetQuoteModal = ({ isOpen, onClose }) => {

  const [message, setMessage] = useState(''); 
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service:''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await fetch("https://corphelp.clearcover.in/api/get-a-quote",{
            method: "POST",
            headers: {
                "Content-Type":"application/json"
            },
            body:JSON.stringify(formData),
        });
        console.log(response)
        if(response.ok){
            setMessage('Your ticket has been raised!');
            console.log("Ticket Raised...");

            setTimeout(() => {
                setMessage(''); // Clear message after 2 seconds
                onClose(); // Close the modal after message disappears
              }, 2000);
        }
        
    } catch (error) {
        console.error(error)
    }
     
    setTimeout(()=>{
        onClose(); 
    },2000) // Close the modal after submission
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-black bg-opacity-25 absolute inset-0" onClick={onClose}></div>
      <div className="bg-white rounded-lg p-8 shadow-lg z-10 ">
        <div className="flex flex-row justify-between mb-4">
            <h2 className="text-xl  text-[#61BC6D] font-extrabold ">Get A Quote</h2>
            <RxCross2 onClick={onClose} size={30} className='text-[#61BC6D] font-extrabold hover:text-red-800'/> 
        </div>
        {/* {message && <div className="text-center text-green-600 font-bold mb-4">{message}</div>} */}
        <form onSubmit={handleSubmit} className='flex flex-col gap-4'>
        <div className="grid grid-cols-1 gap-1">
             <label htmlFor="name"  className='font-medium'>Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
              required
            />
          </div>

          <div className="grid grid-cols-1 gap-1">
            <label htmlFor="email" className='font-medium'>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
              required
            />
          </div>
          <div className="grid grid-cols-1 gap-1">
          <label htmlFor="phone" className='font-medium'>Phone</label>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
              required
            />
          </div>

          <div className="grid grid-cols-1 gap-1">
                                <label htmlFor="service" className='font-medium'>Which service do you need?</label>
                                <select  name="service"
                                         value={formData.service}
                                         onChange={handleChange}
                                         className='p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]'
                                         required >
                                     <option value="">Select</option>
                                     <option value="Group Health Insurance">Group Health Insurance</option>
                                     <option value="WFire & Burglary Insurance">Fire & Burglary Insurance</option>
                                     <option value="Office Package Policy">Office Package Policy</option>
                                     <option value="Group Accidental Insurance">Group Accidental Insurance</option>
                                     <option value="General Liability Insurance">General Liability Insurance</option>
                                     <option value="Aviation Insurance">Aviation Insurance</option>
                                     <option value="Construction Insurance">Construction Insurance</option>
                                     <option value="Marine Insurance">Marine Insurance</option>
                                     <option value="Cyber Security Insurance">Cyber Security Insurance</option>
                                     <option value="Director's & Officers Liability Insurance">Director's & Officers Liability Insurance</option>
                                     <option value="Plant & Machinery Insurance">Plant & Machinery Insurance</option>
                                     <option value="Professional Indemnity Insurance">Professional Indemnity Insurance</option>
                                     <option value="Workmen Compensation Insurance">Workmen Compensation Insurance</option>
                                </select>
             </div>
            <div className="flex justify-center items-center bg-[#61BC6D] rounded-md mt-4">
                <button type="submit" className="p-3 rounded-md text-white font-bold">
                         Talk to an Expert
                </button>
            </div>
          </form>
          {message && <div className="text-center text-green-600 font-bold mt-3">{message}</div>}
      </div>
    </div>
  );
};

export default GwetQuoteModal;
