import star from '../assets/icon-star.png'

function Star() {
  return (
    <div>
        <img src={star} alt="" />
    </div>
  )
}

export default Star