import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const companies = [
    'M/s Excelsoft Technologies Private Limited',
    'CHAITANYA INDIA FIN CREDIT PRIVATE LIMITED',
    // Add more companies as needed
];

function ClaimForm() {
    const [successMessage, setSuccessMessage] = useState(''); // state to manage the success message
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        companyName: '',
        policyNumber: '',
        claimantName: '',
        empCode: '',
        relationship: '',
        hospitalName: '',
        diagnosis: '',
        amountCharged: '',
        email: '',
        phone: '',
        admissionDate: '',
        dischargeDate: '',
        clientCode: '',
        event: "Claim Intimation Request",
        ticket_source: "Employee Portal",
        claimName: '',
    });
    const [suggestions, setSuggestions] = useState([]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Update formData based on input changes
        const updatedFormData = {
            ...formData,
            [name]: value,
        };

        // Dynamically update claimName when claimantName or diagnosis is changed
        if (name === 'claimantName' || name === 'diagnosis') {
            updatedFormData.claimName = `${updatedFormData.claimantName}-${updatedFormData.diagnosis}`;
        }

        setFormData(updatedFormData);

        if (name === 'companyName') {
            const filteredSuggestions = companies.filter(company =>
                company.toLowerCase().includes(value.toLowerCase())
            );
            setSuggestions(filteredSuggestions);
        } else {
            setSuggestions([]); // Clear suggestions for other fields
        }

    };


    const handleSuggestionClick = (suggestion) => {
        setFormData({ ...formData, companyName: suggestion });
        setSuggestions([]); // Clear suggestions after selection
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);

        try {
            const response = await fetch("http://127.0.0.1:8000/api/submit-claim-landing-page", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json(); // Assuming the response is JSON
                console.log('Claim submitted successfully:', result);
                const companyName=result.companyName
                const email=result.email
                const empCode=result.empCode
                const uhid = result.clientCode

                console.log(companyName, email, empCode)

                // Optionally reset form data here
                // setFormData({
                //     companyName: '',
                //     policyNumber: '',
                //     claimantName: '',
                //     empCode: '',
                //     relationship: '',
                //     hospitalName: '',
                //     diagnosis: '',
                //     amountCharged: '',
                //     email: '',
                //     phone: '',
                //     admissionDate: '',
                //     dischargeDate: '',
                //     clientCode: '',
                //     event: "Claim Intimation Request",
                //     ticket_source: "Employee Portal",
                //     claimName: '',
                // });

                setTimeout(() => {
                    const uploadDocUrl = `http://127.0.0.1:3000/uploaddoc?claimant_name=${formData.claimantName}&c_n=${encodeURIComponent(companyName)}&e=${encodeURIComponent(email)}&e_c=${encodeURIComponent(empCode)}
                    &uhid=${encodeURIComponent(uhid)}`;

                    console.log(`Redirecting to upload document URL: ${uploadDocUrl}`);

                    window.location.href = uploadDocUrl; // Redirect to the upload document page
                }, 3000); 
                
            } else {
                console.error('Error submitting claim:', response.statusText);
                
            }
        } catch (error) {
            console.error('Submission failed:', error);
        }
    };

    return (
        <div className="bg-gray-50 py-14">
            <div className="max-w-[80%] lg:max-w-[60%] mx-auto bg-white p-8 shadow-xl rounded-lg">
                <form onSubmit={handleSubmit}>
                    <div className="flex flex-col items-center justify-center">
                        <h1 className='text-2xl font-bold'>Claim Intimation</h1>
                        <hr className='h-[2px] w-[100%] mt-1 bg-green-300' />
                    </div>
                
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8 py-10">
                        {/* Company Name */}
                         {/* Company Name with Suggestions */}
                         <div>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="companyName" 
                                    name="companyName"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.companyName} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="companyName" className="text-lg absolute left-2 top-1 text-gray-500">Company Name</label>
                                {suggestions.length > 0 && (
                                    <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-md mt-1">
                                        {suggestions.map((suggestion, index) => (
                                            <li
                                                key={index}
                                                className="px-4 py-2 hover:bg-green-200 cursor-pointer"
                                                onClick={() => handleSuggestionClick(suggestion)}
                                            >
                                                {suggestion}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>

                        {/* Employee Code */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="empCode" 
                                    name="empCode"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.empCode} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="empCode" className="text-lg absolute left-2 top-1 text-gray-500">Employee Code</label>
                            </div>
                        </div>

                        {/* Policy Number */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="policyNumber" 
                                    name="policyNumber"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.policyNumber} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="policyNumber" className="text-lg absolute left-2 top-1 text-gray-500">Policy No</label>
                            </div>
                        </div>

                        {/* Member Name */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="claimantName" 
                                    name="claimantName"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.claimantName} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="claimantName" className="text-lg absolute left-2 top-1 text-gray-500">Member Name</label>
                            </div>
                        </div>

                        {/* Relationship */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="relationship" 
                                    name="relationship"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.relationship} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="relationship" className="text-lg absolute left-2 top-1 text-gray-500">Relationship</label>
                            </div>
                        </div>

                        {/* Hospital Name */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="hospitalName" 
                                    name="hospitalName"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.hospitalName} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="hospitalName" className="text-lg absolute left-2 top-1 text-gray-500">Hospital Name</label>
                            </div>
                        </div>

                        {/* Diagnosis */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="diagnosis" 
                                    name="diagnosis"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.diagnosis} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="diagnosis" className="text-lg absolute left-2 top-1 text-gray-500">Diagnosis</label>
                            </div>
                        </div>

                        {/* Amount Charged */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="number" 
                                    id="amountCharged" 
                                    name="amountCharged"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.amountCharged} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="amountCharged" className="text-lg absolute left-2 top-1 text-gray-500">Amount Charged</label>
                            </div>
                        </div>

                        {/* Email */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="email" 
                                    id="email" 
                                    name="email"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.email} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="email" className="text-lg absolute left-2 top-1 text-gray-500">Email</label>
                            </div>
                        </div>

                        {/* Phone Number */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="number" 
                                    id="phone" 
                                    name="phone"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.phone} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="phone" className="text-lg absolute left-2 top-1 text-gray-500">Phone Number</label>
                            </div>
                        </div>

                        <div className="flex flex-col xl:flex-row justify-between gap-10">
                               {/* Date of Admission */}
                                <div className='w-full'>
                                    <div className="relative group">
                                        <input 
                                            type="date" 
                                            id="admissionDate" 
                                            name="admissionDate"
                                            placeholder=" " 
                                            className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                            value={formData.admissionDate} 
                                            onChange={handleChange} 
                                            required
                                        />
                                        <label htmlFor="admissionDate"
                                            className={`absolute top-2 text-gray-500 bg-white px-2 transition-all duration-200 ease-out transform 
                                            ${formData.admissionDate ? '-translate-y-8 scale-90 text-[#61BC6D]' : 'opacity-0'} 
                                            group-focus-within:opacity-100 group-focus-within:-translate-y-6 group-focus-within:scale-90 group-focus-within:text-[#61BC6D]`}>
                                            Date of Admission
                                        </label>
                                        {/* <label htmlFor="admissionDate" className="text-lg absolute left-2 top-1 text-gray-500">Date of Admission</label> */}
                                    </div>
                                </div>

                        {/* Date of Discharge */}
                                <div className='w-full'>
                                    <div className="relative group">
                                        <input 
                                            type="date" 
                                            id="dischargeDate" 
                                            name="dischargeDate"
                                            placeholder=" " 
                                            className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                            value={formData.dischargeDate} 
                                            onChange={handleChange} 
                                            required
                                        />
                                        <label htmlFor="dischargeDate"
                                            className={`absolute top-2 text-gray-500 bg-white px-2 transition-all duration-200 ease-out transform 
                                            ${formData.admissionDate ? '-translate-y-8 scale-90 text-[#61BC6D]' : 'opacity-0'} 
                                            group-focus-within:opacity-100 group-focus-within:-translate-y-6 group-focus-within:scale-90 group-focus-within:text-[#61BC6D]`}>
                                            Date of Discharge
                                        </label>
                                    </div>
                                </div>
                        </div>

                        {/* Client Code */}
                        <div>
                            <div className="relative">
                                <input 
                                    type="text" 
                                    id="clientCode" 
                                    name="clientCode"
                                    placeholder=" " 
                                    className="block w-full px-4 py-2 border-2 border-gray-300 rounded-md focus:border-green-200 focus:shadow-md focus:outline-none"
                                    value={formData.clientCode} 
                                    onChange={handleChange} 
                                    required
                                />
                                <label htmlFor="clientCode" className="text-lg absolute left-2 top-1 text-gray-500">Client Code</label>
                            </div>
                        </div>

                    </div>
                    <div className="flex w-full flex-col gap-2 justify-center items-center">
                        <button 
                            type="submit" 
                            className="w-full  lg:w-[80%] px-6 py-3 bg-green-500 text-white font-bold rounded-md hover:bg-green-600 transition duration-300">
                            Submit Claim
                        </button>

                        {/* Display success message */}
                        {successMessage && (
                            <div className="mt-2 text-center text-green-600 font-semibold">
                                {successMessage}
                            </div>
                        )}
                    </div>


                </form>
            </div>
        </div>
    );
}

export default ClaimForm;
