import { useState, useRef, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

function Dropdown() {
  function useClickOutside(ref, onClickOutside) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClickOutside();
        }
      }
      // Bind
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // dispose
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  }

  let [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef("menu");
  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  const listArray = [
    {
      name: "Employee",
      link: "https://corphelp.clearcover.in/login",
    },
    {
      name: "HR Login",
      link: "https://corphelp.clearcover.in/hr_login/login",
    },
    {
      name: "CRM Login",
      link: "https://corphelp.clearcover.in/crm-pages",
    },
  ];

  return (
    <div className="relative w-28 md:w-18" ref={wrapperRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full tracking-wider flex flex-row items-center justify-between p-2 md:w-18 px-3 rounded-full hover:bg-green-600 hover:text-white hover:duration-400"
      >
        Login
        {isOpen ? (
          <FontAwesomeIcon icon={faCaretUp} />
        ) : (
          <FontAwesomeIcon icon={faCaretDown} />
        )}
      </button>
      {/* w-full md:w-18 */}
      {isOpen && (
        <div className="absolute top-25 flex flex-col justify-center items-center rounded-lg w-full md:w-18 bg-sky-100   py-1 duration-400">
          {listArray.map(({ name, link }) => (
            <div
              key={name}
              className="flex w-full md:w-18 text-sm px-2 p-2 rounded-full flex-col items-center hover:bg-green-600 hover:text-white hover:duration-400 mt-1"
            >
              <a href={link} className="">
                {name}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Dropdown;
