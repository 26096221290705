import React,{useState} from 'react'

function GetAQuote() {
  const [message, setMessage] = useState(''); 
   const [formData, setFormData] = useState({
      name: '',
      email: '',
      phone: '',
      service:''
    });
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
          const response = await fetch("https://corphelp.clearcover.in/api/get-a-quote",{
              method: "POST",
              headers: {
                  "Content-Type":"application/json"
              },
              body:JSON.stringify(formData),
          });
          console.log(response)
          if(response.ok){
              setMessage('Your ticket has been raised!');
              console.log("Ticket Raised...");
  
              setTimeout(() => {
                  setMessage(''); 
                
                }, 2000);
            setFormData({
               name: '',
               email: '',
               phone: '',
               service:''
            })
          }
          
       } catch (error) {
          console.error(error)
      }
      
    };

   return (
      <div className="flex flex-row justify-center items-center">
       
         <form onSubmit={handleSubmit} action="">
            <div className=" grid gris-cols-1 gap-2 max-w-[300px] bg-[#fff]  rounded-lg shadow-xl p-4 ">
               <div className="grid grid-cols-1 gap-1">
                  <label htmlFor="name" className='font-medium'>Name</label>
                  <input
                     type="text"
                     name="name"
                     value={formData.name}
                     onChange={handleChange}
                     className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
                     required
                  />
               </div>

               <div className="grid grid-cols-1 gap-1">
                  <label htmlFor="email" className='font-medium'>Email</label>
                  <input
                     type="email"
                     name="email"
                     value={formData.email}
                     onChange={handleChange}
                     className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
                     required
                  />
               </div>
               <div className="grid grid-cols-1 gap-1">
                  <label htmlFor="phone" className='font-medium'>Phone</label>
                  <input
                     type="number"
                     name="phone"
                     value={formData.phone}
                     onChange={handleChange}
                     className="p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]"
                     required
                  />
               </div>
               <div className="grid grid-cols-1 gap-1">
                  <label htmlFor="service" className='font-medium'>Which service do you need?</label>
                  <select name="service"
                     value={formData.service}
                     onChange={handleChange}
                     className='p-2 rounded-md outline-none shadow-sm border-b-[3px] border-r-[3px]'
                     required >
                     <option value="">Select</option>
                     <option value="Group Health Insurance">Group Health Insurance</option>
                     <option value="WFire & Burglary Insurance">Fire & Burglary Insurance</option>
                     <option value="Office Package Policy">Office Package Policy</option>
                     <option value="Group Accidental Insurance">Group Accidental Insurance</option>
                     <option value="General Liability Insurance">General Liability Insurance</option>
                     <option value="Aviation Insurance">Aviation Insurance</option>
                     <option value="Construction Insurance">Construction Insurance</option>
                     <option value="Marine Insurance">Marine Insurance</option>
                     <option value="Cyber Security Insurance">Cyber Security Insurance</option>
                     <option value="Director's & Officers Liability Insurance">Director's & Officers Liability Insurance</option>
                     <option value="Plant & Machinery Insurance">Plant & Machinery Insurance</option>
                     <option value="Professional Indemnity Insurance">Professional Indemnity Insurance</option>
                     <option value="Workmen Compensation Insurance">Workmen Compensation Insurance</option>
                  </select>
               </div>

               <div className="flex flex-row justify-center items-center bg-[#61BC6D] rounded-md mt-4">
                  <button type="submit" className='p-3 rounded-md text-[#ffff] font-bold'>Talk to an Expert</button>
               </div>
            </div>
            {message && <div className="text-center text-green-600 font-bold mb-4">{message}</div>}
         </form>
      </div>
   )
}

export default GetAQuote