import HeaderNav from "../components/HeaderNav";
import MobileNav from "../components/MobileNav";
import Footer from "./Footer";
import ContactUs from "./ContactUs";
// import Blog from "./Blog";
import JoinUs from "./JoinUs";
import Insurance from "./Insurance";
import Services from "./Services";
import HelpBar from "./HelpBar";
import Reviews from "./Reviews";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";

import man from "../assets/Group 2139cc-01.png";
import { useState } from "react";

function Home() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [email, setEmail] = useState("");

  let endpoint = "https://corphelp.clearcover.in/api/website-subscription-form";

  function handleSubscriptionFormChange(e) {
    // console.log(email);
    setEmail(e.target.value);
  }

  function handleSubscriptionFormSubmit(e) {
    e.preventDefault();

    console.log(email);

    if (!email) {
      return;
    }

    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        setEmail("");
        setIsSubmitted(true);
      })
      .catch((err) => console.log(err));
  }

  return (
    <>
      <div style={{ height: "fit-content" }}>
        <div className="hidden md:block">
          <HeaderNav />
        </div>
        <div className="md:hidden">
          <MobileNav />
        </div>

        <div
          id="homebg"
          className="pt-6 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 bg-sky-100"
        >
          <div className="mx-6 lg:mx-20 lg:mt-36 flex flex-col space-y-6 ">
            {/* <div className="w-56 flex flex-col justify-center items-start"> */}
            {/* <div
                id="newBtn"
                className="px-2 ml-2 absolute bg-red-600 text-xs text-slate-50 rounded-full"
              >
                New
              </div>
              <div className="py-2 w-40 bg-red-200 text-xs rounded-full ">
                <p className="ml-14">Get update news</p>
              </div> */}
            {/* </div> */}
            <p className="font-merriweather text-[#252B42] font-bold text-xl">
              ClearCover Insurance:
            </p>
            <p className="flex flex-col gap-2 sm:gap-3 text-2xl sm:text-4xl md:text-5xl  font-bold text-[#252B42]">
              <p>Simplifying Security in a </p>
              <p>Complex World</p>
            </p>
            <p className="text-sm text-gray-500 font-oswald ">
              Transform Your Employee Healthcare Experience <br />
              Comprehensive group health insurance with seamless digital claims{" "}
              <br /> experience
            </p>
            <form onSubmit={handleSubscriptionFormSubmit}>
              <p className="font-semibold text-sm mb-1 text-[#252B42]">
                Get Consultation
              </p>
              <div className="w-64 mt-2 flex flex-col justify-center items-end md:w-96 md:flex md:flex-col md:justify-center md:items-end">
                <FontAwesomeIcon
                  className="absolute self-start pl-2 md:absolute md:self-start md:pl-4 text-gray-500"
                  icon={faArrowUpRightFromSquare}
                />
                <input
                  type="email"
                  name="email"
                  id="consultation-email"
                  onChange={handleSubscriptionFormChange}
                  value={email}
                  placeholder="Enter Email Address"
                  className="w-64 pl-8 md:w-96 h-12 md:pl-12 rounded-full focus:outline-none shadow-md text-xs text-gray-500"
                />
                <button className="w-20 absolute bg-emerald-700 md:w-32 h-10 text-slate-50 text-sm rounded-full  mr-1">
                  Subscribe
                </button>
              </div>
              {isSubmitted && (
                <p className="text-green-700 font-bold text-xl">
                  Message sent successfully!
                </p>
              )}
            </form>
            <div className="flex flex-row space-x-12">
              <div className="flex flex-row space-x-3">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="text-orange-500"
                />
                <a className="text-sm text-[#252B42]" href="#contact-form">
                  Know more
                </a>
              </div>
              <div className="flex flex-row space-x-3">
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  className="text-emerald-600"
                />
                <a
                  className="text-sm text-[#252B42]"
                  href="https://outlook.office365.com/owa/calendar/ClearCoverInsurance@adityatrading.com/bookings/"
                >
                  Schedule a metting
                </a>
              </div>
              <div className="flex flex-row space-x-3">
                <FontAwesomeIcon icon={faPhone} className="text-emerald-600" />
                <a className="text-sm text-[#252B42]" href="tel:7019765044">
                  Call me
                </a>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center">
            <img src={man} alt="man.png" />
          </div>
        </div>
        <Insurance />
        <Services />
        <HelpBar />
        <Reviews />
        <JoinUs />
        {/* <Blog /> */}
        <ContactUs />
        <Footer />
      </div>
    </>
  );
}

export default Home;
