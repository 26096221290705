// import logo from "../assets/LOGO-CC.png";
import logo1 from "../assets/main_logo.png";
import Dropdown from "./Dropdown";

function HeaderNav() {
  return (
    <div
      style={{ fontFamily: "Poppins" }}
      className="px-20 pt-6 w-92 bg-sky-100"
    >
      <nav className="flex flex-row items-center justify-between">
        <img
          src={logo1}
          alt="ClearCover-Logo.png"
          className="w-40 mix-blend-darken"
        />
        <ul className="w-1/2 tracking-wider flex flex-row items-center justify-around md:space-x-1 lg:pace-x-4  text-xl  md:text-sm lg:text-xl font-semibold text-stone-500">
          <li className="p-2 px-3 rounded-full hover:bg-green-600 hover:text-white hover:duration-400">
            <a href="/">Home</a>
          </li>

          <li className="p-2 px-3 rounded-full hover:bg-green-600 hover:text-white hover:duration-400">
            <a href="#about-us">About</a>
          </li>

          <li className="p-2 px-3 rounded-full hover:bg-green-600 hover:text-white hover:duration-400">
            <a href="#insurance">Insurance</a>
          </li>

          <li className="">
            <Dropdown />
          </li>

          <li className="p-2 px-3 rounded-full hover:bg-green-600 hover:text-white hover:duration-400">
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default HeaderNav;
