import React from 'react'
import Navbar from './component/Navbar'
import Home from './pages/Home'
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import Career from './pages/Career';
import FullStackDeveloper from './sections/job/FullStackDeveloper';
import TermCondition from './pages/TermCondition';
import Footer from './component/Footer';
import Login from './pages/Login';

// import Insurance from './sections/insurance/Insurance';
import GroupHealthInsurance from './sections/insurance/GroupHealthInsurance';
import FireInsurance from './sections/insurance/FireInsurance';
import OfficePackagePolicy from './sections/insurance/OfficePackagePolicy';
import GroupAccidentalInsurance from './sections/insurance/GroupAccidentalInsurance';
import GeneralLiabilityInsurance from './sections/insurance/GeneralLiabilityInsurance';
import AviationInsurance from './sections/insurance/AviationInsurance';
import ConstructionInsurance from './sections/insurance/ConstructionInsurance';
import MarineInsurance from './sections/insurance/MarineInsurance';
import CyberSecurityInsurance from './sections/insurance/CyberSecurityInsurance';
import DirectorsInsurance from './sections/insurance/DirectorsInsurance';
import PlantMachineryInsurance from './sections/insurance/PlantMachineryInsurance';
import WorkmanCompensationInsurance from './sections/insurance/WorkmanCompensationInsurance';
import ProfessionalIndeminityInsurance from './sections/insurance/ProfessionalIndeminityInsurance';
import Insurance from './pages/Insurance';
import ScrollTop from './component/ScrollTop';
import InsuranceMain from './sections/insurance/InsuranceMain';
import Team from './pages/Team';
import PlacementAssociate from './sections/job/PlacementAssociate';
import JobForm from './component/JobForm';
import FirstBlog from './sections/blog/FirstBlog';
import BlogGroupHealthInsurance from './sections/blog/BlogGroupHealthInsurance';

function App() {
  return (<>
    <BrowserRouter>
        <Navbar/>
        <ScrollTop/>
       <Routes>
           <Route path="/" element={<Home/>}/>
           <Route path="/about" element={<About/>}/>
           <Route path="/contact" element={<Contact/>}/>
           <Route path="/career" element={<Career/>}/>
           <Route path="/login" element={<Login/>}/>
           <Route path="/insurance" element={<Insurance/>}/>

          {/* #########   Job ######### */}
           <Route path="/fullstack" element={<FullStackDeveloper/>}/>
           <Route path="/placementassociate" element={<PlacementAssociate/>}/>
           <Route path="/jobform" element={<JobForm/>}/>

           <Route path="/term" element={<TermCondition/>}/> 
           <Route path="/team" element={<Team/>}/> 


           {/* Insurance  */}
           <Route path="/insurancemain" element={<InsuranceMain/>}/>
           <Route path="/grouphealth" element={<GroupHealthInsurance/>}/>
           <Route path="/fire" element={<FireInsurance/>}/>
           <Route path="/office" element={<OfficePackagePolicy/>}/>
           <Route path="/groupaccidental" element={<GroupAccidentalInsurance/>}/>
           <Route path="/generalliability" element={<GeneralLiabilityInsurance/>}/>
           <Route path="/aviation" element={<AviationInsurance/>}/>
           <Route path="/construction" element={<ConstructionInsurance/>}/>
           <Route path="/marine" element={<MarineInsurance/>}/>
           <Route path="/cybersecurity" element={<CyberSecurityInsurance/>}/>
           <Route path="/directors" element={<DirectorsInsurance/>}/>
           <Route path="/plant" element={<PlantMachineryInsurance/>}/>
           <Route path="/professional" element={<ProfessionalIndeminityInsurance/>}/>
           <Route path="/workman" element={<WorkmanCompensationInsurance/>}/>

           {/* #####   BLOG ######### */}
           <Route path="/firstBLog" element={<FirstBlog/>}/>
           <Route path="/bloggrouphealthinsurance" element={<BlogGroupHealthInsurance/>}/>


        </Routes>
       <Footer/>
    </BrowserRouter>
    </> )
}

export default App
