import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlane, faTractor } from "@fortawesome/free-solid-svg-icons";
import { faScrewdriverWrench } from "@fortawesome/free-solid-svg-icons";
import { faComputer } from "@fortawesome/free-solid-svg-icons";
import { faCar } from "@fortawesome/free-solid-svg-icons";
import { faPeopleRoof } from "@fortawesome/free-solid-svg-icons";
import { faHouseFire } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { faAnchor } from "@fortawesome/free-solid-svg-icons";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import QuoteModel from "../components/QuoteModel";

function Insurance() {
  const navigate = useNavigate();
  const [quote, setShowQuote] = useState(false);
  const [showMore, setShowMore] = useState(false);

  const handleDivClick = (url) => {
    navigate(url);
  };

  const handleShowModal = (event) => {
    event.stopPropagation(); // Prevent div's onClick event
    setShowQuote(true);
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
      {/* show model here onclick get a quote  */}
      {quote ? <QuoteModel setShowQuote={setShowQuote} /> : <></>}

      <section id="insurance" className="mx-6 my-6 md:mx-12">
        <div className="flex my-6 flex-col justify-center items-center">
          <p className="font-semibold text-xl md:text-2xl lg:text-4xl text-emerald-500">
            Lets find you the Best Insurance
          </p>
        </div>
        <div className="grid grid-rows-1 lg:grid-rows-1  rounded-xl">
          <div className="grid grid-cols-1 p-6 gap-4 lg:grid-cols-5 content-around">
            <div
              onClick={() => handleDivClick("/group-health-insurance")}
              className="flex flex-row lg:flex-col  space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faPeopleRoof}
                size="2xl"
                className="bg-green-100 p-2 rounded-full text-[#252B42]"
              />
              <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                  Group Health Insurance
                </p>

                
                  <button
                    onClick={handleShowModal}
                    // to="/group-health-insurance"
                    className="text-md font-semibold text-sky-500 items-start"
                  >
                    Get a Quote
                  </button>
              </div>
            </div>

            <div
              onClick={() => handleDivClick("/fire-burglary-insurance")}
              className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faHouseFire}
                size="2xl"
                className="bg-green-100 p-2 rounded-full text-[#252B42]"
              />
              <div className="flex flex-col justify-start md:justify-center  items-start lg:items-center space-y-4">
                <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                  Fire & Burglary Insurance
                </p>
                <button
                  onClick={handleShowModal}
                  className="text-md font-semibold text-sky-500"
                >
                  Get a Quote
                </button>
              </div>
            </div>

            <div
              onClick={() => handleDivClick("/office-policy-package")}
              className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faBriefcase}
                size="2xl"
                className="bg-green-100 p-2 rounded-full text-[#252B42] "
              />
              <div className="flex flex-col justify-start md:justify-center items-start lg:items-center space-y-4">
                <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                  Office Package Policy
                </p>
                <button
                  onClick={handleShowModal}
                  className="text-md font-semibold text-sky-500 i ">
                  Get a Quote
                </button>
              </div>
            </div>

            <div
              onClick={() => handleDivClick("/group-accidental-insurance")}
              className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faCar}
                size="2xl"
                className="bg-green-100 p-2 rounded-full text-[#252B42]"
              />
              <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                  Group Accidental Insurance
                </p>
                <button
                  onClick={handleShowModal}
                  className="text-md font-semibold text-sky-500"
                >
                  Get a Quote
                </button>
              </div>
            </div>

            <div
              onClick={() => handleDivClick("/general-liability-insurance")}
              className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
            >
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                size="2xl"
                className="bg-green-100 p-2 rounded-full text-[#252B42]"
              />
              <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                  General Liability Insurance
                </p>
                <button
                  onClick={handleShowModal}
                  className="text-md font-semibold text-sky-500 items-start "
                >
                  Get a Quote
                </button>
              </div>
            </div>
          </div>

          {showMore ? (
            ""
          ) : (
            <div>
              <div
                id="join-us-row-2"
                // className="grid grid-cols-1 p-6 gap-4 lg:flex lg:flex-row lg:justify-center lg:items-center"
                className="grid grid-cols-1 p-6 gap-4 lg:grid-cols-5 content-around "
              >
                <div className="hidden lg:block "></div>
                <div
                  onClick={() => handleDivClick("/aviation-insurance")}
                  className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-center items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faPlane}
                    size="2xl"
                    className="bg-green-100 p-2 rounded-full text-[#252B42]"
                  />
                  <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                    <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                      Aviation Insurance
                    </p>
                    <button
                      onClick={handleShowModal}
                      className="text-md font-semibold text-sky-500"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>

                <div
                  onClick={() => handleDivClick("/construction-insurance")}
                  className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faScrewdriverWrench}
                    size="2xl"
                    className="bg-green-100 p-2 rounded-full text-[#252B42]"
                  />
                  <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                    <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                      Construction Insurance
                    </p>
                    <button
                      onClick={handleShowModal}
                      className="text-md font-semibold text-sky-500"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>

                <div
                  onClick={() => handleDivClick("/marine-cargo-insurance")}
                  className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faAnchor}
                    size="2xl"
                    className="bg-green-100 p-2 rounded-full text-[#252B42]"
                  />
                  <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                    <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                      Marine Insurance
                    </p>
                    <button
                      onClick={handleShowModal}
                      className="text-md font-semibold text-sky-500"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>
                <div className="hidden lg:block"></div>
              </div>

              <div
                id="join-us-row-3"
                className="grid  lg:grid grid-cols-1 p-6 gap-4 lg:grid-cols-5 content-around"
              >
                <div
                  onClick={() => handleDivClick("/cyber-security-insurance")}
                  className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faComputer}
                    size="2xl"
                    className="bg-green-100 p-2 rounded-full text-[#252B42]"
                  />
                  <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                    <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                      Cyber Security Insurance
                    </p>
                    <button
                      onClick={handleShowModal}
                      className="text-md font-semibold text-sky-500"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>

                <div
                  onClick={() => handleDivClick("/directors-insurance")}
                  className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faUsers}
                    size="2xl"
                    className="bg-green-100 p-2 rounded-full text-[#252B42]"
                  />
                  <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                    <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                      Director's & Officers Liability Insurance
                    </p>
                    <button
                      onClick={handleShowModal}
                      className="text-md font-semibold text-sky-500"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>

                <div
                  onClick={() => handleDivClick("/plant-machinery-insurance")}
                  className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faGear}
                    size="2xl"
                    className="bg-green-100 p-2 rounded-full text-[#252B42]"
                  />
                  <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                    <p className="text-sm md:text-lg lg:text-xs flex  font-semibold text-start lg:text-center text-[#2F2F2F]">
                      Plant & Machinery Insurance
                    </p>
                    <button
                      onClick={handleShowModal}
                      className="text-md font-semibold text-sky-500"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>

                <div
                  onClick={() =>
                    handleDivClick("/professional-indemnity-insurance")
                  }
                  className="flex flex-row lg:flex-col space-x-5 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faUserTie}
                    size="2xl"
                    className="bg-green-100 p-2 rounded-full text-[#252B42]"
                  />
                  <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                    <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                      Professional Indemnity Insurance
                    </p>
                    <button
                      onClick={handleShowModal}
                      className="text-md font-semibold text-sky-500"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>

                <div
                  onClick={() =>
                    handleDivClick("/workmen-compensation-insurance")
                  }
                  className="flex flex-row lg:flex-col space-x-4 md:space-x-10 lg:space-x-4 lg:justify-around items-center space-y-4 bg-white rounded-xl shadow-md shadow-gray-400 p-4 cursor-pointer"
                >
                  <FontAwesomeIcon
                    icon={faTractor}
                    size="2xl"
                    className="bg-green-100 p-2 rounded-full text-[#252B42]"
                  />
                  <div className="flex flex-col justify-center items-start lg:items-center space-y-4">
                    <p className="text-sm md:text-lg lg:text-xs font-semibold text-start lg:text-center text-[#2F2F2F]">
                      Workmen Compensation Insurance
                    </p>
                    <button
                      onClick={handleShowModal}
                      className="text-md font-semibold text-sky-500"
                    >
                      Get a Quote
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col justify-center items-center my-8">
          <button
            onClick={handleShowMore}
            className="rounded-full bg-emerald-600 text-gray-50 h-12 w-36 font-semibold"
          >
            {showMore ? "Show More" : "Show Less"}
          </button>
        </div>
      </section>
    </>
  );
}

export default Insurance;
