import React, { useState } from "react";
import cross_img from "../assets/cross_icon.png";

function QuoteModel({ setShowQuote }) {
  const [sendQuery, setSendQuery] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
  });

  const inputHandler = (e) => {
    const { name, value } = e.target;
    setSendQuery({
      ...sendQuery,
      [name]: value,
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(sendQuery);
    let url = "http";
    try {
      const response = await fetch(url, {
        methods: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(sendQuery),
      });
      const res_data = await response.json();
      if (response.ok) {
        alert("Email sent SuccessFully");
      } else {
        alert("Invalid credentials");
      }
    } catch (error) {
      console.error("");
      alert("An error occurred while sending the email");
    }
  };
  return (
    <div className="absolute z-[1] w-full h-[100%] bg-[#00000090] grid">
      <form
        action=""
        style={{ animation: "fadeIn", animationDuration: "2s" }}
        className="place-self-center min-w-[300px] lg:min-w-[400px] max-w-[21vw]  bg-[white] flex flex-col gap-7 py-[25px] px-[30px] rounded-md text-lg text-[black]"
      >
        <div className="flex justify-between ">
          <h2 className="text-2xl font-bold text-[black]">Get a quote</h2>
          <img
            className="h-[15px] w-[15px] cursor-pointer"
            onClick={() => setShowQuote(false)}
            src={cross_img}
            alt=""
          />
        </div>

        <div className="flex flex-col gap-7">
          <input
            onChange={inputHandler}
            name="name"
            value={sendQuery.name}
            className="border p-3 outline-none shadow-md"
            type="text"
            placeholder="Your Name"
            required
          />

          <input
            onChange={inputHandler}
            name="email"
            value={sendQuery.email}
            className="border p-3 outline-none shadow-md"
            type="email"
            placeholder="Your Email"
            required
          />

          <input
            onChange={inputHandler}
            name="phone"
            value={sendQuery.phone}
            className="border p-3 outline-none shadow-md"
            type="number"
            placeholder="Your Phone"
            required
          />
          <select
            onChange={inputHandler}
            name="service"
            value={sendQuery.service}
            className="border p-3 outline-none shadow-md "
            required
          >
            <option value="" disabled>
              Select a Service
            </option>
            <option value="Group Health Insurance">
              Group Health Insurance
            </option>
            <option value="Fire & Burglary Insurance">
              Fire & Burglary Insurance
            </option>
            <option value="Office Package Policy">Office Package Policy</option>
            <option value="Group Accidental Insurance">
              Group Accidental Insurance
            </option>
            <option value="General Liability Insurance">
              General Liability Insurance
            </option>
            <option value="Aviation Insurance">Aviation Insurance</option>
            <option value="Construction Insurance">
              Construction Insurance
            </option>
            <option value="Marine Insurance">Marine Insurance</option>
            <option value="Cyber Security Insurance">
              Cyber Security Insurance
            </option>
            <option value="Director's & Officers Liability Insurance">
              Director's & Officers Liability Insurance
            </option>
            <option value="Plant & Machinery Insurance">
              Plant & Machinery Insurance
            </option>
            <option value="Professional Indemnity Insurance">
              Professional Indemnity Insurance
            </option>
            <option value="Workmen Compensation Insurance">
              Workmen Compensation Insurance
            </option>
          </select>
          <button
            onClick={submitHandler}
            className="p-3 bg-sky-300 shadow-sm text-[white]"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default QuoteModel;
