import male from '../assets/Male.svg'
import female from '../assets/Female.svg' 

const carousal_data = [
  {
    "image": male,
    "name": "Mr.Ajey",
    "company": "Director-HR",
    "review": "Periodic updates on the claims right from the beginning till the end. No need to send reminders to know the status. Happy to see this customer-centric approach for smooth settlement and transparency.",
    "stars": 5
  },
  {
    "image": female,
    "name": "Ms.Sneha",
    "company": "HR Manager",
    "review": "During Covid times we were so panicked about looking for beds in the hospital, thanks to MDH Team to provide us support whenever we needed it. All the very Best !",
    "stars": 4
  },
  {
    "image": male,
    "name": "Mr.Manjunath",
    "company": "Head HR",
    "review": "Access to information is amazing, no need to be dependent on point of contact unless you need which you don't have at your fingertip. Special Thanks to Mr.Shiva for his prompt response.",
    "stars": 4
  },
  {
    "image": female,
    "name": "Ms.Raina",
    "company": "HR Manager",
    "review": "Fantastic service. The team's guidance and support were very helpful. Thank you for being there for us during challenging times. Your dedication and professionalism made a real difference. Keep",
    "stars": 5
  },
  {
    "image": female,
    "name": "Ms.Geetha",
    "company": "Finance Director",
    "review":"Outstanding customer service. The MDH Team's guidance and support throughout the process were exceptional. Thank you for being there for us during challenging times. Keep up the excellent work!",
    "stars": 4
  },  
  {
    "image": male,
    "name": "Mr.Seraj",
    "company": "Director-HR",
    "review": "We have been with MDH for the last 5 years, never seen any difference between commitment and delivery.",
    "stars": 5
  },
  {
    "image": female,
    "name": "Ms.Raina",
    "company": "HR Manager",
    "review": "Top-notch service from the team. Their guidance and support throughout were remarkable. Thank you for your assistance during trying times. Keep up the fantastic work!",
    "stars": 5
  },
  {
    "image": male,
    "name": "Mr.Ranjan",
    "company": "Director-HR",
    "review": "Excellent customer service. The team was supportive and guided us through the entire process. Thanks for your help during difficult periods.",
    "stars": 4
  },
]

const carousal_data2 = [
  {
    "image": male,
    "name": "Mr.Nandan",
    "company": "Human Resources Director",
    "review": "I have received excellent services from MDH. My claim was settled in just few days. Thank You !",
    "stars": 5
  },
  {
    "image": female,
    "name": "Ms.Khusi",
    "company": "Chief HR Officer",
    "review": "Outstanding service. The team provided invaluable guidance and support. Thank you for being with us through difficult periods. Your professionalism and dedication were remarkable !",
    "stars": 4
  },
  {
    "image": male,
    "name": "Mr.Kunal",
    "company": "HR Lead",
    "review": "My claims were settled smoothly thanks to the very supportive team at MDH Happy with the services.",
    "stars": 4
  },
  {
    "image": female,
    "name": "Ms.Asif",
    "company": "Head of Human Resources",
    "review": "Superb service. The team's support and guidance were instrumental during challenging times. Thank you for your assistance. Your hard work and dedication were evident throughout the process.",
    "stars": 5
  },
  {
    "image": female,
    "name": "Ms.Maheshwari",
    "company": "Finance Director",
    "review":"Outstanding customer service. The MDH Team's guidance and support throughout the process were exceptional. Thank you for being there for us during challenging times. Keep up the excellent work!",
    "stars": 4
  },  
  {
    "image": male,
    "name": "Mr.Vishal",
    "company": "HR Coordinator",
    "review": "Our experience with MDH over the past 5 years has shown their dedication to fulfilling their commitments, ensuring a reliable and consistent service.",
    "stars": 4
  },
]


export { carousal_data, carousal_data2 };