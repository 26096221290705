
export const routes = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "About",
    href: "#about-us",
  },
  {
    title: "Insurance",
    href: "#insurance",
  },
  {
    title: "Employee Login",
    href: "https://corphelp.clearcover.in/login",
  },
  {
    title: "HR Login",
    href: "https://corphelp.clearcover.in/hr_login/login",
  },
  {
    title: "CRM Login",
    href: "https://corphelp.clearcover.in/crm-pages",
  },
  {
    title: "Contact",
    href: "#contact",
  },
];