import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function HelpBar() {
  return (
    <section className="bg-gray-100 ">
      <div className=" mt-6 md:mx-12 p-6  grid grid-cols-1 gap-6 lg:grid-cols-3 lg:space-x-28">
        <div>
          <p className="font-bold text-2xl text-[#404040] font-openSans">
            ClearCover Insurance:
          </p>
          <p className="font-bold text-4xl text-[#404040] font-openSans">
            Illuminating your path to security
          </p>
        </div>
        <div className="flex flex-col items-start space-y-6">
          <span className="flex flex-row items-center space-x-6">
            <FontAwesomeIcon
              icon={faBullseye}
              size="2xl"
              className=" p-2 rounded-full"
            />
            <p className="font-bold text-2xl text-[#404040] font-openSans">
              Mission
            </p>
          </span>
          <p className="font-bold text-md text-justify text-[#404040] font-openSans">
            At ClearCover Health, we empower individuals with accessible,
            transparent, and innovative health insurance. We demystify the
            complexities of healthcare coverage, providing clarity and peace of
            mind. Through the application of cutting-edge technology and a
            steadfast commitment to simplicity, we ensure that quality
            healthcare coverage is clear and attainable for all.
          </p>
        </div>
        <div className="flex flex-col items-start space-y-6">
          <span className="flex flex-row items-center space-x-6">
            <FontAwesomeIcon
              icon={faEye}
              size="2xl"
              className=" p-2 rounded-full"
            />
            <p className="font-bold text-2xl text-[#404040] font-openSans">
              Vision
            </p>
          </span>
          <p className="font-bold text-md text-justify text-[#404040] font-openSans">
            At ClearCover Health, we envision a future with comprehensive,
            affordable health insurance for all. We're committed to redefining
            industry standards, fostering trust, and embracing innovation to
            shape a healthier, happier, and more secure tomorrow for our
            members.
          </p>
        </div>
      </div>
    </section>
  );
}

export default HelpBar;
