// import logo from "../assets/LOGO-CC.png";
import logo from "../assets/main_logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

function Footer() {
  return (
    <footer className="grid grid-rows-3 h-1/3 bg-[#F4FFFC] ">
      <div className=" grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 md:mx-20">
        <div className="mt-8 flex flex-col items-center md:mt-8 md:flex md:flex-col md:items-center">
         <a href="/">
         <img
            src={logo}
            alt="ClearCover-Logo.png"
            className="w-44 mix-blend-darken md:w-64"
          /></a>
          <p className="text-md mt-8 mx-2 text-gray-800 md:text-xl text-center font-openSans">
            Comprehensive group health insurance with seamless digital claims
            experience
          </p>
        </div>
        <div className="lg:flex lg:flex-col lg:items-center lg:justify-center">
          <nav>
            <ul className="my-8 flex flex-col items-center justify-around text-md space-y-4">
              <div className="  flex flex-row  flex-wrap items-center justify-around lg:border-x-0 lg:border-b lg:border-stone-300  space-x-4 md:space-x-6 xl:space-x-12 text-ms lg:text-xl pb-1">
                <li>
                  <a href="#" className="font-openSans">
                    About us
                  </a>
                </li>
                <li>
                  <a href="#" className="font-openSans">
                    Business
                  </a>
                </li>
                <li>
                  <a href="#" className="font-openSans">
                    Employee
                  </a>
                </li>
                <li>
                  <a href="#" className="font-openSans">
                    HR Login
                  </a>
                </li>
              </div>
              <div className="space-x-4 flex flex-row items-center  justify-around lg:space-x-12 md:text-xl">
                <li>
                  <a href="#" className="font-openSans">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="#" className="font-openSans">
                    Terms & Conditions
                  </a>
                </li>
              </div>
            </ul>
          </nav>
        </div>
      </div>
      <div className="mx-3 md:mt-20 md:mx-20">
        <p className="text-center leading-relaxed text-md  text-gray-800 md:text-xl md:leading-relaxed font-openSans">
          MDH Insurance is a registered Direct Broker |Registration No. 596,
          Registration Code No. DB 652/16, Valid till 30/04/2026, <br />
          License category- Direct Broker (General)| Visitors are hereby
          informed that their information submitted on the website may be shared{" "}
          <br /> with insurers. Product information is authentic and solely
          based on the information received from the insurers.
        </p>
      </div>
      <div className="mx-12 my-4 lg:flex-row-reverse lg:border-x-0 lg:border-t lg:border-stone-300 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 md:mx-20">
        <div className="flex flex-row items-center justify-center space-x-6 md:justify-center">
          <a href="https://www.facebook.com/clearcoverindia">
            <FontAwesomeIcon
              icon={faFacebook}
              className=" h-10 text-blue-600"
            />
          </a>
          <a href="https://www.linkedin.com/company/clearcover-insurance/">
            <FontAwesomeIcon icon={faLinkedin} className="text-sky-600 h-10" />
          </a>
          <a href="https://x.com/clearcover_insu">
            <FontAwesomeIcon icon={faTwitter} className="text-sky-400 h-10" />
          </a>

          <a href="https://www.youtube.com/channel/UCBh3Y-xnBGDnQo5fKz3JoQQ" target="_blank">
            <FontAwesomeIcon icon={faYoutube} className="text-red-500 h-10" />
          </a>
          <a href="https://www.instagram.com/clearcover_insurance">
            <FontAwesomeIcon
              icon={faInstagram}
              className="text-fuchsia-500 h-10"
            />
          </a>
        </div>
        <div className="flex border-x-0 border-t border-stone-400 flex-row items-center justify-center md:items-center md:justify-center lg:border-0">
          <p className="text-sm  text-gray-800 md:mt-2 md:text-xl font-openSans">
            © Copyright 2023-2024 clearcover.in. All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
