import laptop from "../assets/laptop.png";

function JoinUs() {
  return (
    <section>
      <div className=" bg-slate-100 flex flex-col space-y-6 py-8 mb-8  pt-24 md:pt-14 items-center md:flex-row md:justify-around">
        <p className="px-4  text-center font-bold text-2xl lg:text-4xl text-[#09384D]">
          Join us now for a prosperous and secure future
        </p>
        <button
          disabled
          className="rounded-full  bg-emerald-600 text-gray-50 h-12 w-36 font-semibold lg:lg:text-2xl lg:h-16 lg:w-44"
        >
          Join Us
        </button>
      </div>
      <div className="grid grid-cols-1 md:mx-12 my-16 gap-10 md:grid-cols-2 p-6 rounded-lg">
        <div className="flex flex-row items-center justify-center">
          <img src={laptop} alt="laptop.png" />
        </div>
        <div className="flex flex-col space-y-16 lg:justify-around">
          <div>
            <p className="font-bold text-xl lg:text-4xl text-[#2F2F2F] font-poppins">
              Lets find you the best insurance
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <p className="font-bold text-2xl lg:text-4xl text-[#2F2F2F] font-poppins">
              HR Panel
            </p>
            <p className="lg:text-2xl text-gray-500 font-poppins">
              A dedicated portal to access all your company details along
              with employeee details, claims, E-cards, policy terms and
              documents all in one place.
            </p>
          </div>
          <div className="flex flex-col space-y-4">
            <p className="font-bold text-2xl lg:text-4xl text-[#2F2F2F] font-poppins">
              Employee Panel
            </p>
            <p className="lg:text-2xl text-gray-500 font-poppins">
              A dedicated Employee portal to access all your company details along
              with employeee details, claims, E-cards, policy terms and
              documents all in one place.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JoinUs;
