import shield_mobile from "../assets/shield-mobile.png";
import shield from "../assets/Shield.png";
import Icon_Affordable from "../assets/Icon-Affordable.png";
import Icon_Innovation from "../assets/Icon-EasyClaims.png";
import Icon_Pioneer from "../assets/Icon-Pioneer.png";

function Services() {
  return (
    <section id="about-us"
    className="mx-6 my-8 md:mx-12 grid grid-cols-1 lg:grid-cols-2">
      <div className="flex flex-col justify-center items-center">
        <div className="lg:hidden">
          <img src={shield_mobile} alt="shield_mobile.png" />
        </div>
        <div className="hidden lg:block">
          <img src={shield} alt="shield_mobile.png" className="h-4/5" />
        </div>
      </div>
      <div className="my-6">
        <div className="flex flex-col justify-center items-center">
          <p className="mb-4 font-bold text-2xl sm:text-4xl md:text-5xl lg:text-5xl text-[#252B42] font-merriweather">
            Why choose ClearCover?
          </p>
          <p className="text-gray-500 text-xl">
            Choose ClearCover for reliable, transparent insurance. Trust us for
            full, worry-free living
          </p>
        </div>
        <div className="my-6 flex flex-col gap-2 justify-center space-y-4">
          <div className="flex flex-row space-x-4 items-center bg-[#252B42] rounded-3xl shadow-xl p-4">
            <img src={Icon_Affordable} alt="" />
            <div>
              <p className="text-md font-semibold text-white">
                Affordable Premiums
              </p>
              <p className="text-sm text-white">
                Quality coverage accessible to all, with competitive premiums
                and no compromise on protection
              </p>
            </div>
          </div>
          <div className="flex flex-row  space-x-4 items-center rounded-3xl shadow-xl p-4">
            <img src={Icon_Innovation} alt="" />
            <div>
              <p className="text-md font-semibold text-[#252B42]">
                Fast and Easy Claims
              </p>
              <p className="text-sm text-gray-500 ">
                In the event of a claim, ClearCover's efficient process ensures
                swift resolution, helping you recover quickly
              </p>
            </div>
          </div>
          <div className="flex flex-row space-x-4 items-center rounded-3xl shadow-2xl md:shadow-xl p-4">
            <img src={Icon_Pioneer} alt="" />
            <div>
              <p className="text-md  font-semibold text-[#252B42]">
                Innovation at its Core
              </p>
              <p className="text-sm text-gray-500 ">
                ClearCover pioneers technology for cutting-edge insurance
                solution, continuously evovlving to meet customers' changing
                needs
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;
