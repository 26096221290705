import {carousal_data, carousal_data2} from "./carousal.js";
import Carousel from "better-react-carousel";
import ReviewCard from "../components/ReviewCard.jsx";

function Reviews() {
  return (
    <section
      id="reviews"
      className="bg-sky-100 py-6 flex flex-col justify-center items-center gap-16"
    >
      <div>
        <p className="text-3xl font-bold text-center text-[#2F2F2F] font-openSans">
          Read Honest Reviews of what <br /> Clients Say About Us!
        </p>
      </div>
      <div className="grid grid-cols-1 gap-16">
        <div>
          <Carousel
            cols={4}
            rows={1}
            gap={80}
            hideArrow
            responsiveLayout={[
              {
                breakpoint: 1025,
                cols: 3,
                rows: 1,
                gap: 20,
              },
              {
                breakpoint: 768,
                cols: 2,
                rows: 1,
                gap: 20,
              },
            ]}
          >
            {carousal_data.map((reviewer) => (
              <Carousel.Item key={reviewer.review}>
                <ReviewCard reviewer={reviewer} />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div style={{ height: "14rem" }}>
          <Carousel
            cols={3}
            rows={1}
            gap={100}
            hideArrow
            responsiveLayout={[
              {
                breakpoint: 1025,
                cols: 2,
                rows: 1,
                gap: 20,
              },
              {
                breakpoint: 768,
                cols: 2,
                rows: 1,
                gap: 20,
              },
            ]}
          >
            {carousal_data2.map((reviewer) => (
              <Carousel.Item key={reviewer.review}>
                <ReviewCard reviewer={reviewer} />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
}

export default Reviews;
