import React from "react";
import { Link } from "react-router-dom";

import HeaderNav from "../../components/HeaderNav";
import Insurance from "../Insurance";
import ContactUs from "../ContactUs";
import Footer from "../Footer";
import MobileNav from "../../components/MobileNav";
import Social from "../../assets/Typing-pana.png";

function GeneralLiabilityInsurance() {
  return (
    <div>
      <div className="hidden md:block">
        <HeaderNav />
      </div>
      <div className="md:hidden">
        <MobileNav />
      </div>
      <div id="newBtn"></div>
      <section className="pt-6 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 bg-gradient-to-b from-sky-100 to-white">
        <div className="mx-8 lg:mx-20 md:mt-40">
          <p className="text-gray-500 font-sans">
            <Link to="/" className="text-md font-sans text-gray">
              Home &gt;&nbsp;
            </Link>
            <Link to="/" className="text-md font-sans text-gray">
              Insurance &gt;&nbsp;
            </Link>
            <Link
              to="/general-liability-insurance"
              className="text-md font-sans text-gray"
            >
              General Liability Insurance
            </Link>
          </p>
          <p className="font-sans text-4xl text-zinc-700 my-4">
            General Liability Insurance
          </p>
          <p className="leading-relaxed my-4 font-sans text-sm text-zinc-700 font-medium">
            General Liability Insurance protects businesses from financial
            losses associated with third-party claims for bodily injury,
            property damage, and personal injury.
          </p>
          <button className="my-4 w-20 bg-green-600 md:w-32 h-10 text-slate-50 text-sm rounded-full">
            Get Quote
          </button>
          <p className=" my-4 font-sans font-normal text-zinc-700 text-xl">
            Example:
          </p>
          <p className="leading-relaxed font-sans text-zinc-700 text-sm font-medium">
            LMNOP Café is covered for customer injuries and property damage
            claims, ensuring legal and settlement costs are taken care of.
          </p>
        </div>
        <div className="mx-8 xl:mx-20 lg:mx-5 xl:mt-20 lg:mt-40 mt-20">
          <div>
            <img
              className="lg:w-4/5"
              src={Social}
              alt="Social-Interaction.png"
            />
          </div>
          <div>
            <p className="my-4 font-sans font-medium text-zinc-700 text-xl">
              Key Highlights:
            </p>
            <div>
              <ul className="list-disc text-zinc-700 list-inside text-sm">
                <li className="font-sans my-2 font-medium">
                  Essential for businesses interacting with the public.
                </li>
                <li className="font-sans my-2 font-medium">
                  Covers legal expenses, settlements, and medical costs.
                </li>
                <li className="font-sans my-2 font-medium">
                  Safeguards the reputation of the business in case of lawsuits.
                </li>
                <li className="font-sans my-2 font-medium">
                  May include coverage for advertising liability and product
                  liability.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section>
        <Insurance />
        <div>
          <ContactUs />
        </div>
        <Footer />
      </section>
    </div>
  );
}

export default GeneralLiabilityInsurance;
