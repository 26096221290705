import { useClickAway } from "react-use";
import { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Squash as Hamburger } from "hamburger-react";
import { routes } from "./routes";
import logo from "../assets/LOGO-CC.png";

function MobileNav() {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    document.body.style.overflow = isOpen ? "hidden" : "unset";
    // document.getElementById("newBtn").style.display = isOpen ? "None" : "block";
  }, [isOpen]);

  useClickAway(ref, () => setOpen(false));

  return (
    <div ref={ref}>
      <div className="flex flex-row justify-between p-4 bg-sky-100">
        <img
          src={logo}
          alt="ClearCover-Logo.png"
          className="w-32 mix-blend-darken"
        />
        <Hamburger toggled={isOpen} size={30} toggle={setOpen} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="fixed left-0 shadow-4xl right-0 top-[5rem] p-5 pt-0"
          >
            <ul className="grid gap-2">
              {routes.map((route, idx) => {
                return (
                  <motion.li
                    initial={{ scale: 0, opacity: 0 }}
                    animate={{ scale: 1, opacity: 1 }}
                    transition={{
                      type: "spring",
                      stiffness: 260,
                      damping: 20,
                      delay: 0.1 + idx / 10,
                    }}
                    key={route.title}
                    className="w-full p-[0.08rem] rounded-xl "
                  >
                    <a
                      onClick={() => setOpen((prev) => !prev)}
                      className={
                        "flex items-center justify-between w-full p-[0.4rem] rounded-xl bg-emerald-700"
                      }
                      href={route.href}
                    >
                      <span className="flex gap-1 text-lg px-1 text-stone-100  tracking-wider">
                        {route.title}
                      </span>
                    </a>
                  </motion.li>
                );
              })}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default MobileNav;
