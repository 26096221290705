import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faMapLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faIdCard } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

function ContactUs() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  let endpoint = "https://corphelp.clearcover.in/api/website-contact-form";

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  function handleContactFormChange(e) {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }

  // this method is pass the data from frontend and send email and raise ticken in backend

  async function handleContactFormSubmit(e) {
    e.preventDefault();

    console.log(formData);

    if (!formData.email) {
      return;
    }

    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error("Failed to submit form data");
      }

      const result = await response.json();
      console.log(result);
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      setIsSubmitted(true);
    } catch (error) {
      console.error(error);
    }
  }

  // this function is dirctly  send mail and raise ticket
  // async function handleContactFormSubmit(e) {
  //   e.preventDefault();

  //   console.log(formData);

  //   if (!formData.email) {
  //     return;
  //   }

  //   // Email Data
  //   const emailData = {
  //     to: "abhishek@example.com",
  //     subject: "New Contact Form Submission",
  //     text: `You have a new message from ${formData.name} (${formData.email}): ${formData.message}`,
  //   };

  //   //ticket Data
  //   const ticketData = {
  //     requester: {
  //       name: formData.name,
  //       email: formData.email,
  //     },
  //     subject: "New Contact Form Submission",
  //     service: formData.service,
  //     priority: "normal",
  //   };

  //   try {
  //     // Send email
  //     const emailResponse = await fetch(
  //       "https://api.sendgrid.com/v3/mail/send",
  //       {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer YOUR_SENDGRID_API_KEY`,
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(emailData),
  //       }
  //     );
  //     console.log("Email sent:", emailResponse);

  //     // Raise a ticket
  //     const ticketResponse = await fetch("https://api.neuron.com/tickets", {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(ticketData),
  //     });
  //     console.log("Ticket raised:", ticketResponse);

  //     setFormData({
  //       name: "",
  //       email: "",
  //       message: "",
  //     });
  //     setIsSubmitted(true);
  //   } catch (error) {
  //     console.log("Error:", error);
  //   }
  // }

  return (
    <section
      id="contact"
      className="mx-6 my-8 gap-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 md:mx-20"
    >
      <div>
        <div className="flex flex-col items-start space-y-12">
          <p className="text-4xl font-bold text-[#404040] font-openSans">
            Lets talk to our awesome team
          </p>
          <span className="flex flex-row space-x-5 items-center">
            <div>
              <FontAwesomeIcon icon={faPhoneVolume} />
            </div>
            <div>
              <p className="text-2xl font-bold text-[#404040] font-openSans">
                Call
              </p>
              <p className="text-gray-500 font-openSans">+91 70263 71000</p>
            </div>
          </span>
          <span className="flex flex-row space-x-5 items-center">
            <div>
              <FontAwesomeIcon icon={faEnvelope} />
            </div>
            <div>
              <p className="text-2xl font-bold text-[#404040] font-openSans">
                Email
              </p>
              <p className="text-gray-500 font-openSans">
                info@mdhinsurance.in
              </p>
            </div>
          </span>
          <span className="flex flex-row space-x-5 ">
            <div>
              <FontAwesomeIcon icon={faMapLocationDot} />
            </div>
            <div>
              {/* text-[#404040] */}
              <p className="text-2xl font-bold text-[#404040] hover:text-sky-600 font-openSans cursor-pointer">
                Location
              </p>
              <a href="https://maps.app.goo.gl/r7xTmhRxmms6wi6MA"> <p className="text-gray-500 tracking-wider hover:text-sky-600 font-openSans">
                ClearCover Insurance, 1st Floor, 2gethr ORR, <br /> Tower B,
                Mantri Commercio, Devarabisanahalli, <br /> Near Sakra World
                Hospital, Kariyammana Agrahara, <br /> Bellandur, Bengaluru,
                Karnataka 560103
              </p></a>
            </div>
          </span>
        </div>
      </div>
      <div className="bg-[#FCFCFC] px-4 py-8 rounded-lg">
        <form onSubmit={handleContactFormSubmit}>
          <div className="flex flex-col space-y-8">
            <span className="flex flex-col space-y-2">
              <div>
                <label htmlFor="" className="font-openSans text-[#2F2F2F]">
                  Your Name
                </label>
              </div>
              <div className="flex flex-col">
                <FontAwesomeIcon
                  icon={faIdCard}
                  className="absolute pl-3 pt-4"
                />
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleContactFormChange}
                  placeholder="Full Name Here"
                  className="h-12 rounded-lg pl-14 shadow-2xl focus:outline-none font-openSans"
                />
              </div>
            </span>
            <span className="flex flex-col space-y-2">
              <div>
                <label htmlFor="" className="font-openSans text-[#2F2F2F]">
                  Your Email
                </label>
              </div>
              <div className="flex flex-col">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="absolute pl-3 pt-4"
                />
                <input
                  type="email"
                  name="email"
                  onChange={handleContactFormChange}
                  value={formData.email}
                  placeholder="Your email address"
                  className="h-12 rounded-lg pl-14 shadow-2xl focus:outline-none font-openSans"
                />
              </div>
            </span>
            <span className="flex flex-col space-y-2">
              <label htmlFor="message" className="font-openSans text-[#2F2F2F]">
                Message
              </label>
              <textarea
                placeholder="Type your message here"
                name="message"
                rows={4}
                onChange={handleContactFormChange}
                value={formData.message}
                className="rounded-lg p-4 shadow-2xl focus:outline-none align-text-top font-openSans"
              />
            </span>
            <span>
              {isSubmitted ? (
                <p className="text-green-700 font-bold text-xl font-openSans">
                  Message sent successfully!
                </p>
              ) : (
                <button className="bg-[#404040] text-gray-50 text-sm p-4 rounded-lg font-openSans hover:bg-[#2e2c2c]">
                  Send Message
                </button>
              )}
            </span>
          </div>
        </form>
      </div>
    </section>
  );
}

export default ContactUs;
