import PropTypes from "prop-types";
import Star from "./Star.jsx";


function ReviewCard(props) {
  const { reviewer } = props;

  return (
    <div className="flex flex-col items-start space-y-2 p-6 bg-white rounded-lg h-72">
      <span className="flex flex-row items-center space-x-6">
        <img src={reviewer.image} alt={reviewer.image} />
        <span className="flex flex-col items-center justify-start">
          <p className="font-bold text-xl text-[#09384D] font-urbanist">{reviewer.name}</p>
          <p className=" text-lg text-gray-500 font-urbanist">{reviewer.company}</p>
        </span>
      </span>
      <div className="h-full flex flex-col items-start justify-between">
        <p className="text-md text-justify text-gray-500 font-urbanist">
          {reviewer.review}
        </p>
        <div className="flex flex-row w-24 mt-2">
          {Array.from({ length: reviewer.stars }, (_, i) => (
            <Star key={i} />
          ))}
        </div>
      </div>
    </div>
  );
}


ReviewCard.propTypes = {
  reviewer: PropTypes.object,
};

export default ReviewCard;
