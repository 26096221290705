import { BrowserRouter, Routes, Route } from "react-router-dom";

import GroupHealthInsurance from "./pages/insurance_pages/GroupHealthInsurance";
import AviationInsurance from "./pages/insurance_pages/AviationInsurance";
import ConstructionInsurance from "./pages/insurance_pages/ConstructionInsurance";
import CyberSecurityInsurance from "./pages/insurance_pages/CyberSecurityInsurance";
import DirectorsInsurance from "./pages/insurance_pages/DirectorsInsurance";
import FireBurglaryInsurance from "./pages/insurance_pages/FireBurglaryInsurance";
import GeneralLiabilityInsurance from "./pages/insurance_pages/GeneralLiabilityInsurance";
import GroupAccidentalInsurance from "./pages/insurance_pages/GroupAccidentalInsurance";
import MarineCargo from "./pages/insurance_pages/MarineCargo";
import PlantMachinery from "./pages/insurance_pages/PlantMachinery";
import PersonalIndemnityInsurance from "./pages/insurance_pages/ProfessionalIndemnityInsurance";
import WorkmenCompensation from "./pages/insurance_pages/WorkmenCompensation";
import OfficePackagePolicy from "./pages/insurance_pages/OfficePackagePolicy";
import ScrollToTop from "./components/ScroolTop";

import Home from "./pages/Home";

export default function App() {
  return (
    <div className="grid grid-rows-[auto_1fr_auto]  h-screen">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route
            path="/group-health-insurance"
            element={<GroupHealthInsurance />}
          ></Route>
          <Route
            path="aviation-insurance"
            element={<AviationInsurance />}
          ></Route>
          <Route
            path="construction-insurance"
            element={<ConstructionInsurance />}
          ></Route>
          <Route
            path="cyber-security-insurance"
            element={<CyberSecurityInsurance />}
          ></Route>
          <Route
            path="directors-insurance"
            element={<DirectorsInsurance />}
          ></Route>
          <Route
            path="fire-burglary-insurance"
            element={<FireBurglaryInsurance />}
          ></Route>
          <Route
            path="general-liability-insurance"
            element={<GeneralLiabilityInsurance />}
          ></Route>
          <Route
            path="group-accidental-insurance"
            element={<GroupAccidentalInsurance />}
          ></Route>
          <Route
            path="marine-cargo-insurance"
            element={<MarineCargo />}
          ></Route>
          <Route
            path="plant-machinery-insurance"
            element={<PlantMachinery />}
          ></Route>
          <Route
            path="professional-indemnity-insurance"
            element={<PersonalIndemnityInsurance />}
          ></Route>
          <Route
            path="workmen-compensation-insurance"
            element={<WorkmenCompensation />}
          ></Route>
          <Route
            path="office-policy-package"
            element={<OfficePackagePolicy />}
          ></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
